import { Paper, Typography } from "@mui/material"
import UserEngagementChart from "../components/charts/UserEngagementChart";
import MentalHealthImpactChart from "../components/charts/MentalHealthImpactChart";

const Insights = () => {
    return (
        <Paper sx={{padding: 3}}>
            <Typography component="h5" variant="h5" gutterBottom>
                Insights
            </Typography>
            <MentalHealthImpactChart />
            <UserEngagementChart />
        </Paper>
    )
}

export default Insights;
