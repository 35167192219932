import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BusinessClient from "../../lib/BusinessClient";
import { isChartError } from "./chartHelper";
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles('dark', {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
      ...theme.applyStyles('dark', {
        backgroundColor: '#308fe8',
      }),
    },
}));

const MentalHealthImpactChart = () => {
    const [data, setData] = useState<number | undefined>(undefined);
    const [error, setError] = useState<string>();
    const [variant, setVariant] = useState<"determinate"|"indeterminate">("indeterminate");

    useEffect(() => {
        // fetch data
        const fetch = async () => {
            const result = await BusinessClient.instance.mentalHealthImprovement();
            // check if error or data is returned
            if (isChartError(result)) {
                setError(result.error);
                return;
            }
            setData(result as number);
            setVariant("determinate");
            setError(undefined);

        }
        fetch();
    }
    , []);

    return <Card sx={{padding: 3, marginBottom: 3}}>
        <Typography variant="h6" gutterBottom>
                Mental Health Impact
        </Typography>
        { error &&
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <InfoIcon sx={{ color: 'text.secondary', mr: 1 }} />
          <Typography color="textSecondary">{error}</Typography>
        </Box>
        }
        {! error &&
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <BorderLinearProgress variant={variant} value={data} />
          </Box>
        <Box sx={{ minWidth: 35 }}>
          { data &&
          <Typography
            variant="body2"
            sx={{ color: 'text.secondary' }}
          >{`${Math.round(data)}%`}</Typography> }
        </Box>
        </Box>
        }
        <Typography variant="body1" gutterBottom style={{marginTop: '1rem'}}>
            {data && <>
            {data?.toFixed(0)}% der Nutzenden verzeichnen eine Verbesserung ihrer mentalen Gesundheit.
            </>}
            {!data && !error && <>Berechne...</>}
        </Typography>
        </Card>
}

export default MentalHealthImpactChart;