import { Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import BusinessClient, { userEngagementData } from "../../lib/BusinessClient";
import {
    CartesianGrid,
    Line,
    ComposedChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip
} from "recharts";
import { isChartError } from "./chartHelper";

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    if (month === 'Jan') return `${month} ${date.getFullYear()}`;
    return `${month}`;
};

const getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
};

interface ChartPoint {
    label: string;
    count?: number;
    expected?: number;
}

const UserEngagementChart = () => {
    const [data, setData] = useState<ChartPoint[]>();
    const [error, setError] = useState<string>();

    useEffect(() => {
        const fetch = async () => {
            const result = await BusinessClient.instance.userEngagement();
            if (isChartError(result)) {
                setError(result.error);
                return;
            }

            const originalData = result as userEngagementData[];

            const now = new Date();
            const currentYear = now.getFullYear();
            const currentMonth = now.getMonth(); // 0-indexed
            const currentMonthStr = `${(currentMonth + 1).toString().padStart(2, "0")}`;
            const currentLabel = `${currentYear}-${currentMonthStr}`;
            const daysPassed = now.getDate();
            const totalDays = getDaysInMonth(currentYear, currentMonth);

            const augmentedData: ChartPoint[] = originalData.map((entry) => {
                const count = entry.count;
                const label = entry.label;

                // current month
                if (label === currentLabel) {
                    let projected = Math.round((entry.count / daysPassed) * totalDays);
                    if(daysPassed < 2) projected = originalData[originalData.length - 2].count;

                    return {
                        label,
                        count: entry.count,
                        projected
                    };
                }
                // previous month
                else if (label === originalData[originalData.length - 2].label) {
                    return {
                        label,
                        count,
                        projected: count
                    };
                }
                // others
                return {
                    label,
                    count,
                };
            });

            setData(augmentedData);
        };
        fetch();
    }, []);

    return (
        <Card sx={{ padding: 3, marginBottom: 3 }}>
            <Typography variant="h6" gutterBottom>
                User Engagement
            </Typography>
            {error && <Typography color="error">{error}</Typography>}
            {data &&
                <ResponsiveContainer width="100%" height={400}>
                    <ComposedChart data={data}>
                    <Tooltip
                        content={({ payload, label }) => {
                            if (!payload || payload.length === 0) return null;

                            // Prüfen, ob der aktuelle Tooltip dem letzten Eintrag entspricht
                            const isLastItem = data && label === data[data.length - 1].label;

                            return (
                                <div style={{ backgroundColor: 'white', padding: 10, border: '1px solid #ccc' }}>
                                    <strong>{formatDate(label)}</strong>
                                    <br />
                                    {payload.map((item, index) => {
                                        const color = item.color;
                                        let displayName = item.name;

                                        if (item.name === 'count') displayName = 'Interaktionen';
                                        else if (item.name === 'projected' && isLastItem) displayName = 'prognostiziert';
                                        else if (item.name === 'projected') return null; // ausblenden für nicht-letztes

                                        return (
                                            <div key={index} style={{ color }}>
                                                {item.value} {displayName}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="label" tickFormatter={formatDate} />
                        <YAxis />
                        <Tooltip />
                        <Line
                            type="monotone"
                            dataKey="count"
                            stroke="#8884d8"
                            dot={false}
                            animationDuration={3000}
                            animationEasing="ease-out"
                        />
                        <Line
                            type="monotone"
                            dataKey="projected"
                            stroke="orange"
                            strokeDasharray="5 5"
                            dot={{ r: 3 }}
                            animationDuration={1000}
                            animationBegin={3000}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
            }
        </Card>
    );
};

export default UserEngagementChart;